<template>
  <div class="error--page-404">
    <div class="error--page-404-wrapper">
      <svg-icon
        name="page-not-found"
        width="100%"
        height="250px"
        class="svg-icon"
      ></svg-icon>

      <div class="error--page-404-right">
        <div class="error--page-404-right-wrapper">
          <h2 class="error--page-404-title">{{ $t(`${base}.title`) }}</h2>
          <el-divider />
          <p class="error--page-404-description">{{
            $t(`${base}.description`)
          }}</p>
          <el-button type="primary" @click="handleClick">{{
            $t(`${base}.go_home`)
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticMetaMixin from '@/utils/mixin/static-meta'

export default {
  name: 'LadError404',
  mixins: [StaticMetaMixin],
  data() {
    return {
      base: 'page404',
    }
  },
  methods: {
    handleClick() {
      this.$router.push({ name: 'index' })
    },
  },
}
</script>

<style lang="scss">
.error--page-404 {
  min-height: calc(100vh - 150px);
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;

  &-wrapper {
    margin: 0 auto;
  }

  &-title {
    font-weight: bold;
    font-size: 2.2em;
    color: #6ea252;
  }

  &-description {
    font-size: 1.2em;
    color: #4c4f56;
  }

  @media screen and (max-width: 450px) {
    .svg-icon {
      height: 200px;
    }

    &-title {
      font-size: 1.8em;
    }

    &-description {
      font-size: 1em;
    }
  }
}
</style>
